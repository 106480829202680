

.height {
    &-50 {
        height: 50px !important;
    }

    &-100 {
        height: 100px !important;
    }
}

.ml-250 {
    margin-left: 250px !important;
}

.ml-leftbar-menu {
    margin-left: var(--margin-left-menu) !important;
  }

.vh-content {
    height: calc(100vh - 170px);

    &-min {
        min-height: calc(100vh - 170px)
    }
}

@mixin vh-x-list {
    @for $i from 1 through 100 {
        .vh-#{$i} {
            height: #{$i}vh;
        }
    }
}
@include vh-x-list;


.width-100 {
    width: 100px !important;
}

.width-150 {
    width: 150px !important;
}

.width-200 {
    width: 200px !important;
}

.width-250 {
    width: 250px !important;
}

.width-300 {
    width: 300px !important;
}

.cursor {
    &-pointer {
        cursor: pointer;
    }
}

.loader-wrapper {
    position: fixed;
    top: 0;
    left: var(--margin-left-menu);
    right: 0;
    bottom: 0;
    //width: 100%;
    background-color: #f1f3fa;
    z-index: 9999;

    @media (max-width:767.98px) {
        left: 0;
    }

    .loader {
        width: 56px;
        height: 56px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -28px 0 0 -28px;
    }
}

.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    padding: 0 !important;
    margin: 0 !important;
    overflow: auto;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    outline: none;

    :hover, :focus {
        color: var(--color-button-hover);
        text-decoration: none;
    }
}

.card {
    .card-widgets {
        button {
            color: inherit;
            font-size: 18px;
            display: inline-block;
            line-height: 1;
        }
    }
}


textarea::-webkit-input-placeholder {
    color: var(--color-sub-medium-gray);
    opacity: 1; }
textarea::-moz-placeholder {
    color: var(--color-sub-medium-gray);
    opacity: 1; }
textarea:-ms-input-placeholder {
    color: var(--color-sub-medium-gray);
    opacity: 1; }
textarea::-ms-input-placeholder {
    color: var(--color-sub-medium-gray);
    opacity: 1; }
textarea::placeholder {
    color: var(--color-sub-medium-gray);
    opacity: 1; }
//.link-button:hover,
//.link-button:focus {
//    text-decoration: none;
//}


.chat-input {
    height: 50px;
    width: 100%;
    background-color: var(--chat-input-background-color);
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #e9e9e9;
    margin: 0;
    position: absolute;
    bottom: 0;
    input {
        font-family: NanumSquare, Arial;
        font-size: 16px;
        margin: 0;
        border: none;
        width: 100%;
        color: var(--chat-input-text-color);
        background-color: rgba(0,0,0,0)
    }
    input[type=text]::-ms-clear {
      display:none;
    }
    input:focus {
        outline: none !important;
    }
    .send-button {
        color: var(--chat-input-send-button-color);
    }

    ::placeholder {
      color: var(--chat-input-placeholder-color);
    }
    ::-webkit-input-placeholder {
      color: var(--chat-input-placeholder-color);
    }
    :-ms-input-placeholder {
      color: var(--chat-input-placeholder-color);
    }
}

.drag-handle {
    cursor: grab;
}

.grabbing {
    cursor: grabbing;
}

.grabbing * {
    cursor: grabbing;
}

.react-contextmenu-submenu {
    .react-contextmenu {
        overflow: auto;
        height: 500px;
        pointer-events: none;
    }
}
